<template>
  <div class="wameed-dashboard-page-content" v-if="getUserInfo">

    <div class="user-detail-cards mb-5">
      <financial-card
          class="w-100"
          :number="getUserInfo.total_sales"
          :title="$t('financial.card.total_sales')"
          icon="f-profit2-icon"
      />

      <financial-card
          class="w-100"
          :number="getUserInfo.his_profit"
          :title="$t('financial.card.company_profit')"
          icon="f-profit-minus-icon"
          :is_price="true"
      />

      <financial-card
          class="w-100"
          :number="getUserInfo.our_profit"
          :title="$t('financial.card.our_from_order_profit')"
          icon="f-profit-plus-icon"
          :is_price="true"
      />

      <financial-card
          class="w-100"
          :number="getUserInfo.products_count"
          :title="$t('financial.card.total_products')"
          icon="f-products-icon"
          :is_price="false"
      />

      <financial-card
          class="w-100"
          :number="getUserInfo.orders_count"
          :title="$t('financial.card.total_orders')"
          icon="f-orders-icon"
          :is_price="false"
      />

    </div>
    <b-row>

      <b-col cols="12" md="6" class="p-0">

        <b-card
            no-body class=" wameed-card  m-0">


          <div class="person-info">
            <div class="data">


              <img v-if="getUserInfo.bg && showImage" :src=getUserInfo.bg :alt=getUserInfo.name @error="onError"
                   class="person-img">
              <div v-else class="person-img">
               <span>
                  <d-person-icon/>
               </span>
              </div>
              <img v-if="getUserInfo.image && showImage" :src=getUserInfo.image :alt=getUserInfo.name @error="onError"
                   class="person-img">
              <div v-else class="person-img">
               <span>
                  <d-person-icon/>
               </span>
              </div>

              <div class="col">
                <div class="name">{{ getUserInfo.name }}</div>
                <div class="phone">
                  {{ getUserInfo.orders_count }} {{ $t('common.order') }} -
                  <span class="text-main">{{ formatNumber(getUserInfo.total_profit) }}</span>
                </div>
              </div>
            </div>

          </div>
          <div class="px-5 pb-2">

            <div class="divider mb-2"></div>

            <div class="tile-text between mb">
              <div class="text">{{ $t('users.vendor.table.name') }}</div>
              <div class="value">{{ getUserInfo.name }}</div>
            </div>

            <div class="tile-text between mb">
              <div class="text">{{ $t('users.vendor.table.category') }}</div>
              <div class="value">{{ getUserInfo.type }}</div>
            </div>

            <div class="tile-text between mb">
              <div class="text">{{ $t('users.table.phone') }}</div>
              <div class="value">{{ getUserInfo.phone1 }}
                <template v-if="getUserInfo.phone2">
                  {{ getUserInfo.phone2 }}
                </template>
                <template v-if="getUserInfo.phone3">
                  {{ getUserInfo.phone3 }}
                </template>
              </div>

            </div>

            <div class="tile-text between mb">
              <div class="text">{{ $t('users.table.email') }}</div>
              <div class="value">{{ getUserInfo.email }}</div>
            </div>


            <div class="tile-text between mb">
              <div class="text">{{ $t('users.vendor.table.address') }}</div>
              <div class="value">{{ getUserInfo.address }}</div>
            </div>

            <div class="tile-text between mb">
              <div class="text">{{ $t('users.card.address_on_map') }}</div>
              <a class="value link text-main"
                 :href="geolocationLink(getUserInfo.geo_location)"
                 target="_blank">
                {{ $t('users.table.show_addresses') }}
              </a>
            </div>


            <div class="tile-text between mb">
              <div class="text">{{ $t('users.vendor.form.min_order') }}</div>
              <div class="value">{{ formatNumber(getUserInfo.min_order_price) }}</div>
            </div>


            <div class="tile-text col mb p-0" style="gap: 0">
              <div class="text">{{ $t('users.vendor.table.description') }}</div>
              <div class="value">{{ getUserInfo.description }}</div>
            </div>

          </div>
        </b-card>
      </b-col>
    </b-row>


  </div>
</template>

<script>
import AddressesModal from "@/views/pages/users/clients/components/addressesModal";

import {mapGetters} from 'vuex';
import FinancialCard from "@/components/financialCard";

export default {

  components: {
    FinancialCard,
    AddressesModal
  },
  data() {
    return {
      showImage: true,
      showAddressesModal: false,
    };
  },
  computed: {
    ...mapGetters({
      getUserInfo: 'admin/users/detail/getUserInfo',
    }),


  },
  created() {

    this.$store.dispatch('admin/users/detail/unsetData');
    this.$store.dispatch('admin/users/detail/info', {
      id: this.$route.params.id,
      type: 'vendors'
    })
  },

  methods: {
    onError() {
      this.showImage = false;
    },

    geolocationLink(temp) {
      if (temp) {
        let location = temp.split(',');
        let locationLat = location[0];
        let locationLng = location[1];
        return `https://www.google.com/maps/place/${locationLat},${locationLng}/@${locationLat},${locationLng},15z`
      }
      return '';
    }

  },
};
</script>
